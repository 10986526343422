import React, { useState, useRef, useEffect } from "react";
import "./WellnessLibrary.css";

import { FiSearch } from "react-icons/fi";
import { FaRegBookmark, FaHistory } from "react-icons/fa";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { FaBookmark, FaSliders } from "react-icons/fa6";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import {
  useIsLibraryLoading,
  useIsLibraryVideosLoading,
  useLibraryCategories,
  useLibraryCategoryContent,
  useSelectedLibraryCategory,
  useUserTokenSelector,
} from "store/reducers";
import {
  getWellnessCategories,
  getWellnessLibHistoryVideos,
  getWellnessLibSavedVideos,
  getWellnessSelectedCategoryVideos, saveWellnessLibVideo,
  unsaveWellnessLibraryVideo
} from "actions/wellnesslibrary";
import { useDispatch } from "react-redux";
import {
  setVideoItem,
  setWellnessLibraryCategories,
  setWellnessLibraryCategoriesLoading,
  setWellnessLibraryCategoryData,
  setWellnessLibraryCategoryLoading,
  setWellnessLibraryData,
  setWellnessLibrarySelectedCategory
} from "store/actions";
import { useHistory } from "react-router-dom";
import Skeleton from 'react-loading-skeleton';

const WellnessLibrary = ({ showMessage, showError }) => {
  const navRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [scrollWidth, setScrollWidth] = useState(0);
  const [clientWidth, setClientWidth] = useState(0);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [savedVideos, setSavedVideos] = useState([]);
  const [savedVideoIds, setSavedVideoIds] = useState([]);
  const [historyVideos, setHistoryVideos] = useState([]);
  const [loadingSaved, setLoadingSaved] = useState(true);
  const [loadingHistory, setLoadingHistory] = useState(true);
  const [message, setMessage] = useState("");
  const [savedActive, setSavedActive] = useState(false);
  const [historyActive, setHistoryActive] = useState(false);
  const [searchText, setSearchText] = useState("");
  const userToken = useUserTokenSelector();
  const categories = useLibraryCategories();
  const selectedCategory = useSelectedLibraryCategory();
  const categoriesLoading = useIsLibraryLoading();
  const isCategoryVideosLoading = useIsLibraryVideosLoading(selectedCategory);
  const wellnessCategoryContent = useLibraryCategoryContent(selectedCategory);
  const flattenedWellnessCategoryContent = wellnessCategoryContent?.flatMap(category => category.content) || [];
  const [showFilterModal, setShowFilterModal] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const handleScroll = () => {
      if (navRef.current) {
        setScrollLeft(navRef.current.scrollLeft);
        setScrollWidth(navRef.current.scrollWidth);
        setClientWidth(navRef.current.clientWidth);
      }
    };

    handleScroll();

    const currentNavRef = navRef.current;
    if (currentNavRef) {
      currentNavRef.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", handleScroll);
    }

    return () => {
      if (currentNavRef) {
        currentNavRef.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", handleScroll);
      }
    };
  }, []);

  const getWellnessLibCategories = async () => {
    try {
      dispatch(setWellnessLibraryCategoriesLoading(true));
      const response = await getWellnessCategories(userToken);
      const { status, error, categoryList: categories = [] } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      let category = "";
      category = categories[0]?.category;
      dispatch(setWellnessLibraryCategories(categories));
      dispatch(setWellnessLibrarySelectedCategory(category));
      const libraryData = {};
      for (const category of categories) {
        libraryData[category.category] = null;
      }
      dispatch(setWellnessLibraryData(libraryData));
    } catch (error) {
      console.log(error);
      showError(error.message);
    } finally {
      dispatch(setWellnessLibraryCategoriesLoading(false));
    }
  };

  const getWellnessLibCategoryVideos = async () => {
    try {
      dispatch(setWellnessLibraryCategoryLoading({ selectedCategory: selectedCategory, value: true }));
      const response = await getWellnessSelectedCategoryVideos(userToken, selectedCategory);
      const { status, error } = response.data;
      if (status !== "success") {
        throw new Error(error);
      }
      dispatch(setWellnessLibraryCategoryData({ selectedCategory: selectedCategory, value: response.data.data || [] }));
    } catch (error) {
      console.log(error);
      showError(error.message);
    } finally {
      dispatch(setWellnessLibraryCategoryLoading({ selectedCategory: selectedCategory, value: false }));
    }
  };

  const getSavedWellnessVideo = async () => {
    try {
      setLoadingSaved(true);
      const response = await getWellnessLibSavedVideos(userToken);
      if (response.data.status === "success") {
        setSavedVideos(response.data.video);
      }
      setLoadingSaved(false);
    }
    catch (error) {
      console.log(error);
    }
  };

  const getSavedWellnessHistoryVideos = async () => {
    try {
      setLoadingHistory(true);
      const response = await getWellnessLibHistoryVideos(userToken);
      if (response.data.status === "success") {
        setHistoryVideos(response.data.data);
      }
      setLoadingHistory(false);
    }
    catch (error) {
      console.log(error);
    }
  };

  const saveWellnessLibraryCategoryVideo = async (bucketName, s3ThumbnailKey, s3VideoKey, title, duration) => {
    const response = await saveWellnessLibVideo(userToken, {
      bucketName,
      s3ThumbnailKey,
      s3VideoKey,
      videoName: title,
      duration: String(duration),
    });
    if (response.data.status === "success") {
      getSavedWellnessVideo();
      setOpenSnackbar(true);
      setSavedVideoIds((prevSavedIds) => {
        if (prevSavedIds.includes(title)) {
          // If the video is already saved, return back
          return;
        } else {
          // If the video is not saved, add it to the array (save)
          return [...prevSavedIds, title];
        }
      });
      setOpenSnackbar(false);
    }
  };

  const unsaveWellnessLibVideos = async (s3VideoKey, tab) => {
    const response = await unsaveWellnessLibraryVideo(userToken, s3VideoKey);
    if(response.data.status === "success") {
      setOpenSnackbar(true);
      getSavedWellnessVideo();
      setMessage(response.data.message || "Video unsaved successfully");
      setOpenSnackbar(false);
    }
    if(tab === 'saved' && filteredSavedLibraries.length == 0) {
      setSavedActive(!savedActive)
    }
  }

  const handleDecrease = () => {
    if (navRef.current) {
      navRef.current.scrollLeft -= 160;
    }
  };

  const handleIncrease = () => {
    if (navRef.current) {
      navRef.current.scrollLeft += 160;
    }
  };

  const handleSaveItem = (item) => {
    setSaved((prevSaved) => {
      const isSaved = prevSaved.some((savedItem) => savedItem.id === item.id);
      if (isSaved) {
        setMessage("Item removed from saved!");
        return prevSaved.filter((savedItem) => savedItem.id !== item.id);
      } else {
        setMessage("Item saved!");
        return [...prevSaved, item];
      }
    });
    setOpenSnackbar(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const handleSave = () => {
    setSavedActive(!savedActive);
    setHistoryActive(false);
    dispatch(setWellnessLibrarySelectedCategory(""));
  };

  const handleHistory = () => {
    setHistoryActive(!historyActive);
    setSavedActive(false);
    dispatch(setWellnessLibrarySelectedCategory(""));
  };

  const handleVideoNavigate = (bucketName, videoKey, item, timeDuration) => {
    const data = { ...item, duration: timeDuration };
    dispatch(setVideoItem(data));
    history.push(`/dashboard/library/video?bucketName=${bucketName}&videoKey=${videoKey.split("/").join("_")}`);
  };

  const categoryDurationMap = { Short: 5, Med: 10, Long: 15 };

  const isLeftDisabled = scrollLeft === 0;
  const isRightDisabled = scrollLeft + clientWidth >= scrollWidth;

  const filteredSavedLibraries = savedVideos.filter((item) => item?.videoName?.toLowerCase()?.includes(searchText.toLowerCase()));
  const filteredWatchedLibraries = historyVideos.filter((item) =>
    item?.videoName?.toLowerCase()?.includes(searchText.toLowerCase())
  );


  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
  };

  const filteredItems = flattenedWellnessCategoryContent.filter((item) => item.title.toLowerCase().includes(searchText.toLowerCase()));

  // Render Skeleton while loading
  const renderSkeletons = (count) => {
    return Array.from({ length: count }).map((_, index) => (
        <div key={index} className="practice-div">
            <div className="lib-thumbnail">
                <Skeleton height={210} />
            </div>
            <div className="lib-infos" style={{display:'flex',flexDirection:'column'}}>
                <Skeleton width={120} />
                <Skeleton width={80} />
            </div>
        </div>
    ));
};

  useEffect(() => {
    if (!categoriesLoading && !(categories && categories?.length > 0)) {
      getWellnessLibCategories();
    }
    getSavedWellnessVideo();
    getSavedWellnessHistoryVideos();
  }, []);

  useEffect(() => {
    if (selectedCategory && !isCategoryVideosLoading && !(wellnessCategoryContent && wellnessCategoryContent?.length > 0)) {
      getWellnessLibCategoryVideos();
    }
  }, [selectedCategory]);

  useEffect(() => {
    const initialSavedIds = savedVideos.map((video) => video.videoName);
    setSavedVideoIds(initialSavedIds);
  }, [savedVideos]);

  return (
    <div className="wellness-page">
      <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      <div className="wellness-top">
        <div className={`wsearch-div`} style={{position:'relative'}}>
          <div className="wsearch">
            <FiSearch size={18} />
            <input
              type="text"
              className="search-input"
              placeholder="Search"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <button onClick={() => setShowFilterModal(!showFilterModal)} >
              <FaSliders size={18} />
            </button>
          </div>
              {showFilterModal && (
                  // <div className="modal-backdrop" onClick={() => setShowFilterModal(false)}>
                      <div className="libraray-modal-content" onClick={(e) => e.stopPropagation()}>
                          <div className="filter-options">
                              <label><input type="checkbox" /> 5 min</label>
                              <label><input type="checkbox" /> 10 min</label>
                              <label><input type="checkbox" /> 15 min</label>
                              <label><input type="checkbox" /> 20 min</label>
                          </div>
                      </div>
                  // </div>
                )}
        </div>
        <div className="wellness-topright">
          <button className={savedActive ? "wtop-btn active" : "wtop-btn"} onClick={handleSave}>
            <FaRegBookmark size={20} color={savedActive ? "white" : "#394B42"} />
            <p>Saved</p>
          </button>
          <button className={historyActive ? "wtop-btn active" : "wtop-btn"} onClick={handleHistory}>
            <FaHistory size={20} color={historyActive ? "white" : "#394B42"} />
            <p>History</p>
          </button>
        </div>
      </div>
      <div className="library-div">
        <div className={`arrow-icon ${isLeftDisabled ? "disabled" : ""}`} onClick={!isLeftDisabled ? handleDecrease : null}>
          <IoIosArrowBack size={20} color={!isLeftDisabled ? "#394B42" : "#394B4280"} />
        </div>
        <div className="library-nav" ref={navRef}>
          {categoriesLoading ? (
                    <div className="library-nav">
                        <Skeleton width={80} height={80} count={7} style={{ marginLeft:'20px',borderRadius:'50px' }} />
                    </div>
                    ) : (
          categories?.map((category, index) => (
            <div
              key={index}
              className={`lib-div`}
              onClick={() => {
                dispatch(setWellnessLibrarySelectedCategory(category.category));
                setSavedActive(false)
                setHistoryActive(false)
              }}
            >
              <div className={`lib-icon ${category.category == selectedCategory ? "selected" : ""}`}>
                <img src={category.iconUrl} alt={category.category} />
              </div>
              <p>
                {category.category.split(" ").map((word, index) => (
                  <React.Fragment key={index}>
                    {word}
                    <br />
                  </React.Fragment>
                ))}
              </p>
            </div>
          )))}
        </div>
        <div className={`arrow-icon ${isRightDisabled ? "disabled" : ""}`} onClick={!isRightDisabled ? handleIncrease : null}>
          <IoIosArrowForward size={20} color={!isRightDisabled ? "#394B42" : "#394B4280"} />
        </div>
      </div>
      <div className="wellness-content">
        {savedActive && (
          <div className="wcontent-div">
            <div className="wcontent-links">
              {loadingSaved ? renderSkeletons(6) :  filteredSavedLibraries.map((item, index) => (
                <div
                  key={index}
                  className="practice-div"
                  onClick={() => handleVideoNavigate(item.bucketName, item.s3VideoKey, item, item.duration)}
                >
                  <div className="lib-thumbnail">
                    <div
                      className="bookmark-icon"
                      onClick={(event) => {
                        event.stopPropagation();
                        unsaveWellnessLibVideos(item.s3VideoKey, 'saved')
                      }}
                      // onClick={() => saveWellnessLibraryCategoryVideo(item.bucketName, item.s3ThumbnailKey, item.s3VideoKey, item.title)}
                    >
                      {savedVideos.some((savedItem) => savedItem.id === item.id) ? (
                        <FaBookmark size={20} color="#394B42" />
                      ) : (
                        <FaRegBookmark size={20} color="#394B42" />
                      )}
                    </div>
                    <img src={item.thumbnailUrl} alt={item.videoName} />
                  </div>
                  <div className="lib-infos">
                    <div className="lib-info">
                      <p className="lib-name">{truncateText(item.videoName, 20)}</p>
                      {/* <div className="lib-content">
                        <img src="images/coins.png" alt="coins" />
                        <span>{item.coins}</span>
                      </div> */}
                    </div>
                    <div className="lib-info">
                      <p className="lib-content">{item.duration || 10} Min</p>
                      <p className="lib-content">{item.views} views</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        {historyActive && (
          <div className="wcontent-div">
            <div className="wcontent-links">
              {loadingHistory ? renderSkeletons(6) : filteredWatchedLibraries.map((item, index) => {
                return (
                <div
                  key={index}
                  className="practice-div"
                  onClick={(e) => {
                    handleVideoNavigate(item.bucketName, item.s3VideoKey, item, item.duration);
                  }}
                >
                  <div className="lib-thumbnail">
                    <div
                      className="bookmark-icon"
                      onClick={(event) =>
                        {
                          event.stopPropagation();
                          if(savedVideoIds.includes(item.videoName)) {
                            unsaveWellnessLibVideos(item.s3VideoKey, history)
                          } else {
                          saveWellnessLibraryCategoryVideo(
                            item.bucketName,
                            item.s3ThumbnailKey,
                            item.s3VideoKey,
                            item.videoName,
                            item.duration
                          )
                        }
                        }
                      }
                    >
                      {savedVideoIds.includes(item.videoName) ? (
                          <FaBookmark size={20} color="#394B42" />
                        ) : (
                          <FaRegBookmark size={20} color="#394B42" />
                        )}
                    </div>
                    <img src={item.thumbnailUrl} alt={item.videoName} />
                  </div>
                  {/* <div className="watch-div">
                                        <div style={{ width: `${(item.watchTime / item.duration) * 100}%` }} className="watch-time"></div>
                                    </div> */}
                  <div className="lib-infos history">
                    <div className="lib-info">
                      <p className="lib-name">{truncateText(item.videoName, 20)}</p>
                      {/* <div className="lib-content">
                        <img src="/images/coins.png" alt="coins" />
                        <span>{item.coins}</span>
                      </div> */}
                    </div>
                    <div className="lib-info">
                      <p className="lib-content">{item.duration || 10} Min</p>
                      <p className="lib-content">{item.views} views</p>
                    </div>
                  </div>
                </div>
              )})}
            </div>
          </div>
        )}
        {!historyActive && !savedActive && (
          <>
            <div className="wcontent-div">
              {/* <p className="wcontent-head">Top picks for you</p> */}
              <div className="wcontent-links">
                {isCategoryVideosLoading ? renderSkeletons(6) : filteredItems.map((item, index) => (
                  <div
                    key={index}
                    className="practice-div"
                    onClick={() => handleVideoNavigate(item.bucketName, item.videoKey, item, categoryDurationMap[item.category])}
                  >
                    <div className="lib-thumbnail">
                      <div
                        className="bookmark-icon"
                        onClick={(event) =>
                          {
                            event.stopPropagation();
                            if(savedVideoIds.includes(item.title)) {
                              unsaveWellnessLibVideos(item.videoKey, 'main')
                            } else {
                            saveWellnessLibraryCategoryVideo(
                              item.bucketName,
                              item.imageKey,
                              item.videoKey,
                              item.title,
                              categoryDurationMap[item.category]
                            )
                          }
                          }
                        }
                      >
                        {savedVideoIds.includes(item.title) ? (
                          <FaBookmark size={20} color="#394B42" />
                        ) : (
                          <FaRegBookmark size={20} color="#394B42" />
                        )}
                      </div>
                      <img src={item.image} alt={item.category} />
                    </div>
                    <div className="lib-infos">
                      <div className="lib-info">
                        <p className="lib-name">{truncateText(item.title, 24)}</p>
                      </div>
                      <div className="lib-info">
                        <p className="lib-content">{item.views} views</p>
                        {/* <div className="lib-content">
                          <img src="/images/coins.png" alt="coins" />
                          <span>{item.coins}</span>
                        </div> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default WellnessLibrary;
