import React, { useEffect, useState } from "react";
import "./MyAnalytics.css";
import { FaEdit } from "react-icons/fa";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import DailyGoals from "components/DailyGoals/DailyGoals";
import CaloriesIcon from "assets/svg_icons/calories_icon.svg";
import StepsIcon from "assets/svg_icons/steps_icon.svg";
import SocialMediaIcon from "assets/svg_icons/social_media_icon.svg";
import CoinsIcon from "assets/svg_icons/coins.svg";
import StreakIcon from "assets/svg_icons/streak_fire.svg";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis } from "recharts";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { getAssessmentData } from "actions/dataLoad";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { Link, useHistory } from "react-router-dom";
import { loadAllQuiz } from "actions/quiz";
import { IoSearch } from "react-icons/io5";
import { motion } from "motion/react";
import Lottie from "lottie-react";
import blissWithTrademill from '../../assets/JsonAnimations/blissWithTrademill.json'

const years = [new Date().getFullYear(), new Date().getFullYear() - 1];
const monthOptions = [
  { name: "All", fullName: "All Months", value: "all"},
  { num: 1, name: "Jan", fullName: "January" , value: "01"},
  { num: 2, name: "Feb", fullName: "February", value: "02" },
  { num: 3, name: "Mar", fullName: "March", value: "03" },
  { num: 4, name: "Apr", fullName: "April", value: "04" },
  { num: 5, name: "May", fullName: "May" , value: "05"},
  { num: 6, name: "June", fullName: "June", value: "06" },
  { num: 7, name: "July", fullName: "July", value: "07" },
  { num: 8, name: "Aug", fullName: "August", value: "08" },
  { num: 9, name: "Sep", fullName: "September", value: "09" },
  { num: 10, name: "Oct", fullName: "October", value: "10" },
  { num: 11, name: "Nov", fullName: "November", value: "11" },
  { num: 12, name: "Dec", fullName: "December", value: "12" },
];

const MyAnalytics = ({ showError, showMessage }) => {
  const [selectedDate, setSelectedDate] = useState("today");
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [assessmentData, setAssessmentData] = useState([]);
  const [wellnessScore, setWellnessScore] = useState(0);
  const [healthData, setHealthData] = useState([]);
  const [showAppPopup, setShowAppPopup] = useState(false);
  const [editGoals, setEditGoals] = useState(false);
  const [userFirstBookingYear, setUserFirstBookingYear] = useState("");
  const token = useUserTokenSelector();
  const userInfo = useUserInfoSelector();
  const history = useHistory();
  const dispatch = useDispatch();
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
  };

  const handleMonthChange = (event) => {
    if(event.target.value === "All Months") {
      setSelectedMonth("all")
    } else {
    setSelectedMonth(event.target.value);
    }
  };

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const getUserJoinDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // JavaScript months are 0-indexed
    return { year, month };
  };

  const { year: joinYear, month: joinMonth } = getUserJoinDate(userFirstBookingYear);

  const years = joinYear === new Date().getFullYear() ? [new Date().getFullYear()] : [joinYear, new Date().getFullYear()];


  const monthOptions = [
    { name: "All", fullName: "All Months", value: "all" },
    ...[...Array(12)].map((_, i) => ({
      num: i + 1,
      name: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"][i],
      fullName: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"][i],
      value: String(i + 1).padStart(2, "0"),
    })),
  ];

  const filteredMonths = selectedYear === new Date().getFullYear() 
  ? monthOptions.filter(month => month.num <= new Date().getMonth() + 1) // Show months only up to the current month
  : monthOptions;

  const fetchAssessmentData = async (monthNumber, year = "") => {
    try {
      dispatch(startLoading());
      const response = await getAssessmentData(
        {
          month: monthNumber,
          year: year,
        },
        token
      );
      setAssessmentData(response.data.data);
      setHealthData(response.data.healthData);
      setWellnessScore(response.data.wellnessScore);
      setUserFirstBookingYear(response.data.userTimestamp);
    } catch (error) {
      showError(error.response?.data?.message || error.response?.data?.error || "An unknown error occurred");
      console.error(error);
    } finally {
      dispatch(stopLoading());
    }
  };

  useEffect(() => {
    if (selectedMonth && selectedYear) {
      fetchAssessmentData(selectedMonth, selectedYear);
    }
  }, [selectedMonth, selectedYear]);

  // Determine colors for bars based on the levels
  const getBarColor = (level) => {
    switch (level) {
      case "Mild":
        return "#96A8FF"; // Color for Mild
      case "Moderate":
        return "#F6D169"; // Color for Moderate
      case "Severe":
        return "#F1573F"; // Color for Severe
      case "No Sign":
        return "#49AF7C";
      default:
        return "#49AF7C"; // Default fallback color
    }
  };

  const handleAppPopupOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      setShowAppPopup(false);
    }
  }

  return (
    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="analytics-page">
      <div className="analytics-div top">
        <div className="awellness-top">
          <div className="awellness-head">
            <span className="wellness-head">
              My Wellness Score <span className="score">{wellnessScore}/10</span>
            </span>
            {/* <div className="analytics-filter">
              <select value={selectedDate} onChange={handleDateChange}>
                <option value="today">Today</option>
                <option value="yesterday">Yesterday</option>
              </select>
            </div> */}
          </div>
        </div>
        <div className="goals-description">
          <div className="goals-left">
            <Lottie
              animationData={blissWithTrademill} 
              loop={true}
            />
          </div>
          <div className="goals-middle">
            <div className="goals">
              <span>Daily Goals</span>
              {/* <FaEdit size={20} onClick={() => {}} /> */}
            </div>
            <div className="goal-div">
              <button className="icon-div" onClick={() => setShowAppPopup(true)}>
                <img src={CaloriesIcon} alt="calories" />
              </button>
              <div className="goals-desc">
                <span className="value">{healthData.find((item) => item.type === "Calories")?.value || 0}</span>
                <p className="head">Calories Burnt</p>
              </div>
            </div>
            <div className="goal-div">
              <button className="icon-div" onClick={() => setShowAppPopup(true)}>
                <img src={StepsIcon} alt="steps" />
              </button>
              <div className="goals-desc">
                <span className="value">{healthData.find((item) => item.type === "Steps")?.value || 0}</span>
                <p className="head">Steps</p>
              </div>
            </div>
            <div className="goal-div">
              <button className="icon-div" onClick={() => setShowAppPopup(true)}>
                <img src={SocialMediaIcon} alt="social_media" />
              </button>
              <div className="goals-desc">
                <span className="value">
                  {(() => {
                    const val = healthData.find((item) => item.type === "SocialMediaDetox")?.value || 0;
                    const hours = Math.floor(val / 60);
                    const mins = val % 60;
                    return `${hours}h ${mins}m`;
                  })() || 0}
                </span>
                <p className="head">Social Media Usage</p>
              </div>
            </div>
          </div>
          <div className="goals-right">
            <div className="coins-streak">
              <img src={CoinsIcon} alt="coins" />
              <div className="right" style={{marginLeft:'11px'}}>
                <p className="value">{userInfo.coinsBalance}</p>
                <p className="head">Happiness Coins</p>
              </div>
            </div>
            <div className="coins-streak">
              <img src={StreakIcon} alt="streak" />
              <div className="right" style={{marginLeft:'18px'}}>
                <p className="value">{userInfo.streakCount}</p>
                <p className="head">Day Streak</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="analytics-div bottom">
        <div className="awellness-head awellness-head-assessment">
          <span className="analytics-heading">Assessment Analytics</span>
          <div className="analytics-filters">
            <div className="analytics-filter">
              <select value={selectedMonth} onChange={handleMonthChange}>
                {filteredMonths.map((month) => (
                  <option value={month.num} key={month.num}>{month.fullName}</option>
                ))}
              </select>
            </div>
            <div className="analytics-filter">
              <select value={selectedYear} onChange={handleYearChange}>
                {years.map((year) => (
                  <option key={year} value={year}>{year}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        <div className="assessment-content">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={assessmentData}>
              <CartesianGrid strokeDasharray="3 3" horizontal={true} vertical={false} />
              <XAxis dataKey="quizName" />
              <YAxis />
              <Bar dataKey="userScore" name="Value" barSize={50}>
                {assessmentData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getBarColor(entry.identifier)} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className="assessment-tags">
          <div className="assessment-tag">
            <div style={{ backgroundColor: getBarColor("No Sign") }} />
            No Sign
          </div>
          <div className="assessment-tag">
            <div style={{ backgroundColor: getBarColor("Mild") }} />
            Mild
          </div>
          <div className="assessment-tag">
            <div style={{ backgroundColor: getBarColor("Moderate") }} />
            Moderate
          </div>
          <div className="assessment-tag">
            <div style={{ backgroundColor: getBarColor("Severe") }} />
            Severe
          </div>
        </div>
        <button className="measure-btn" onClick={() => history.push("/dashboard/assessments")}>
          <span>Measure Now</span>
          <IoIosArrowForward />
        </button>
      </div>
      {editGoals && <DailyGoals onClose={() => setEditGoals(false)} />}
      {showAppPopup && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} className="modal-overlay" onClick={handleAppPopupOverlayClick}>
          <div className="analytics-modal-content" >
          <img 
            src="https://ucarecdn.com/bd5c587e-94f4-4cd0-bf00-5d805509508d/crossIcon.svg" 
            alt="Close" 
            className="analytics-close-icon" 
            onClick={handleAppPopupOverlayClick}
          />
                        <p style={{fontSize:'1.5em',fontWeight:'800px',margin:'0px'}}>Want to track your progress in the challenge? </p>
                        <p>The best way to do it is through our mobile app. Just download the GoodLives app and you’ll be able to easily join the challenge and keep track of your progress! 📱📈</p>
            <div className="image-row">
              <a href="https://play.google.com/store/apps/details?id=com.goodlives.wellness&hl=en_IN">
                <img
                  src="https://ucarecdn.com/0c775cb4-3b83-4835-b550-8c470657ed18/playStoreImage.svg"
                  alt="Play Store logo"
                  className="store-icon"
                />
              </a>
              <a href="https://apps.apple.com/in/app/goodlives/id6740541252">
                <img
                  src="https://ucarecdn.com/2788f9eb-7f8c-4307-a871-1b8e1cc3abb6/appStoreImage.svg"
                  alt="App Store Logo"
                  className="store-icon"
                />
              </a>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

export default MyAnalytics;
