import React, { useState, useEffect, useRef } from "react";
import "./Events.css";
import { IoIosArrowBack, IoIosArrowForward, IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { addWorkshop, getCurrentWorkshops } from "actions/workshop";
import { useDispatch } from "react-redux";
import { startLoading, stopLoading } from "store/actions";
import { useUserTokenSelector } from "store/reducers";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";

const Events = () => {
  const navRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [expandedEventIndices, setExpandedEventIndices] = useState([]);
  const intervalRef = useRef(null);
  const [currentWorkshops, setCurrentWorkshops] = useState([]);
  const dispatch = useDispatch();
  const [severity, setSeverity] = useState("error");
  const [open, setOpen] = useState(false);
  const userToken = useUserTokenSelector();
  const [loggedOutMessage, setLoggedOutMessage] = useState("");
  const [addedWorkshops, setAddedWorkshops] = useState({});

  useEffect(() => {
    getWorkshops();
  }, []);

  const showMessage = (text, severity) => {
    setLoggedOutMessage(text);
    setSeverity(severity);
    setOpen(true);
  };

  const getWorkshops = async () => {
    dispatch(startLoading());
    const date = new Date();
    const res = await getCurrentWorkshops(userToken, date);
    setCurrentWorkshops(res.data.workshops);
    dispatch(stopLoading());
  };

  const handleJoinWorkshop = async (workshopLink) => {
    try {
      window.open(workshopLink, "_blank");

    } catch (error) {
      showMessage(error.response.data.error, "error");
    }
  };

  const handleAddToCalendar = async (workshopId) => {
    dispatch(startLoading())
    const res = await addWorkshop(userToken, { workshopId });
    dispatch(stopLoading())
      // Update the state to reflect that this specific workshop has been added
    setAddedWorkshops((prevState) => ({
      ...prevState,
      [workshopId]: true, // Mark this workshop as added
    }));
    getWorkshops();
    showMessage(res.data ? JSON.stringify(res.data) : "Workshop added to calendar successfully!", "success");
  };

  const handleToggle = (index) => {
    setExpandedEventIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((i) => i !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const updateScrollPosition = (newIndex) => {
    const navElement = navRef.current;
    if (navElement) {
      const eventWidth = navElement.scrollWidth / currentWorkshops.length;
      const newScrollLeft = newIndex * eventWidth - (navElement.clientWidth / 2 - eventWidth / 2);
      navElement.scrollTo({
        left: newScrollLeft,
        behavior: 'smooth',
      });
    }
  };

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const getOrdinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const day = date.getUTCDate();
    const month = monthNames[date.getUTCMonth()];
    const year = date.getUTCFullYear();

    return `${day}${getOrdinalSuffix(day)} ${month}, ${year}`;
  };

  const formatTime = (startTimeStr, endTimeStr) => {
    const startTime = new Date(startTimeStr);
    const endTime = new Date(endTimeStr);

    const formatSingleTime = (time) => {
      let hours = time.getHours();  // Get local hours
      const minutes = time.getMinutes();  // Get local minutes
      const ampm = hours >= 12 ? 'pm' : 'am';  // Determine am/pm
      hours = hours % 12;  // Convert 24-hour time to 12-hour format
      hours = hours ? hours : 12;  // Handle midnight (0 hours)
      const strMinutes = minutes < 10 ? '0' + minutes : minutes;  // Format minutes with leading zero if needed
      return `${hours}:${strMinutes} ${ampm}`;  // Return formatted time
    };

    return `${formatSingleTime(startTime)} - ${formatSingleTime(endTime)}`;
  };

  const resetInterval = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(() => {
      handleIncrease();
    }, 5000);
  };

  const handleDecrease = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex === 0 ? currentWorkshops.length - 1 : prevIndex - 1;
      updateScrollPosition(newIndex);
      return newIndex;
    });
    resetInterval();
  };

  const handleIncrease = () => {
    setActiveIndex((prevIndex) => {
      const newIndex = prevIndex === currentWorkshops.length - 1 ? 0 : prevIndex + 1;
      updateScrollPosition(newIndex);
      return newIndex;
    });
    resetInterval();
  };

  useEffect(() => {
    if (navRef.current && currentWorkshops.length > 0) {
      updateScrollPosition(activeIndex);
    }

    intervalRef.current = setInterval(() => {
      handleIncrease();
    }, 10000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [activeIndex, currentWorkshops]);


  // Function to check button status for current event
  const checkButtonStatus = (index) => {
    if (index >= currentWorkshops.length) return;

    const now = new Date();
    const eventStart = new Date(currentWorkshops[index].workshopStartDatetime);

    const timeDifference = (eventStart - now) / 1000 / 60; // Time difference in minutes

    return timeDifference <= 15 // Button should be "Join Now" if the event starts within 15 minutes
  };

  // Automatically check and update button status every 10 seconds
  useEffect(() => {
    const statusInterval = setInterval(() => {
      // Re-render the component to check button status for the current active event
      setActiveIndex((prevIndex) => prevIndex);
    }, 10000); // Check every 10 seconds

    return () => clearInterval(statusInterval); // Cleanup interval on unmount
  }, [currentWorkshops]);

  const activeEvent = currentWorkshops[activeIndex] || {};

  // Add condition to check if no workshops are found
  if (currentWorkshops.length === 0) {
    return (
      <div style={{ justifyItems: 'center', marginTop: '160px' }}>
        <img src="https://ucarecdn.com/6b63a345-f67d-4763-a3a6-15f01b863f25/noEvents.svg" alt="noEvents" style={{ height: '150px', with: '150px' }} />
        <p style={{ marginTop: '10px' }}>No events right now.. Stay tuned!</p>
      </div>
    );
  }

  const handleClose = (reason) => {
    if (reason === "clickaway") {
      setOpen(false);
    }
    setOpen(false);
    setLoggedOutMessage("");
  };

  return (
    <div className="events-page">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
          {loggedOutMessage}
        </Alert>
      </Snackbar>
      <p className="events-head">Upcoming Events</p>

        <div className="event-info desktop">
          <div className="left">
            <p className="name">{activeEvent.workshopTopic}</p>
            <div className="date-time">
              <p>{formatDate(activeEvent.workshopStartDatetime)}</p>
              <p>{formatTime(activeEvent.workshopStartDatetime, activeEvent.workshopEndDatetime)}</p>
            </div>
            <p className="desc">{activeEvent.workshopDescription}</p>
            <button className="join-btn" onClick={() => checkButtonStatus(activeIndex) ? handleJoinWorkshop(activeEvent?.workshopLink) : handleAddToCalendar(activeEvent._id)} disabled={addedWorkshops[activeEvent._id]}>
              {/* {checkButtonStatus(activeIndex) ? <p>Join Now</p> : <p>Add to calendar</p>} */}
              {checkButtonStatus(activeIndex) ? (
              <p>Join Now</p>
            ) : (
              <p>{activeEvent?.addedToCalendar ? "Added to Calendar" : "Add to calendar"}</p>
            )}
            </button>
          </div>
          <div className="right">
            <img src={activeEvent.workshopImageUrl} alt="event" />
          </div>
        </div>
      <div className="mobile-events">
        {currentWorkshops.map((event, i) => (
          <div key={i} className={`event-info ${expandedEventIndices.includes(i) ? 'expanded' : ''}`}>
            <div className="left">
              <div className="info-top">
                <div className="date-name">
                  <p className="date">{formatDate(event.workshopStartDatetime)}</p>
                  <p className="name">{event.workshopTopic}</p>
                </div>
                <div className="expand-icon">
                  {expandedEventIndices.includes(i) ? (
                    <IoIosArrowUp onClick={() => handleToggle(i)} color={"white"} size={20} />
                  ) : (
                    <IoIosArrowDown onClick={() => handleToggle(i)} color={"white"} size={20} />
                  )}
                </div>
              </div>
              {expandedEventIndices.includes(i) && (
                <div className="info-bottom">
                  {/* <p className="by">{"ABC"}</p> */}
                  <p className="desc">{event.workshopDescription}</p>
                  <button className="join-btn" onClick={() => checkButtonStatus(i) ? handleJoinWorkshop(event?.workshopLink) : handleAddToCalendar(event._id)} disabled={addedWorkshops[event._id]}>
                  {checkButtonStatus(i) ? (
                      <p>Join Now</p>
                    ) : (
                      <p>{event?.addedToCalendar ? "Added to Calendar" : "Add to calendar"}</p>
                    )}
                  </button>
                </div>
              )}
            </div>
            <div className="right">
              <img src={event.workshopImageUrl} alt="event" />
            </div>
          </div>
        ))}
      </div>
      <div className="events-div">
        {currentWorkshops.length >= 3 ? <div className={`arrow-icon`} onClick={handleDecrease}>
          <IoIosArrowBack size={20} color={"#394B42"} />
        </div>: null}
        <div className="events-nav" ref={navRef}>
          {currentWorkshops.map((event, index) => (
            <div key={index} className={`event-div ${index === activeIndex ? 'eventActive' : ''}`} onClick={() => setActiveIndex(index)}>
              <div className="event-thumbnail">
                <img src={event.workshopImageUrl} alt="event" />
              </div>
              <p >{event.workshopTopic}</p>
            </div>
          ))}
        </div>
        {currentWorkshops.length >= 3 ? <div className={`arrow-icon`} onClick={handleIncrease}>
          <IoIosArrowForward size={20} color={"#394B42"} />
        </div>: null}
      </div>
    </div>
  );
};

export default Events;