//library imports
import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Switch, Route, useParams, useLocation } from "react-router-dom";
import { refreshToken } from "./actions/auth";
import { loadAllFeatures } from "./actions/features";
import { useDispatch } from "react-redux";
import { lazy } from "react";
import { withRouter } from "react-router-dom";

//Routes
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const TherapistPrivateRoute = lazy(() => import("./components/TherapistPrivateRoute"));
const AdminPrivateRoute = lazy(() => import("./components/AdminPrivateRoute"));

//utilities
import Loading from "./components/Utilities/Loading/Loading";

//screens
const AboutUs = lazy(() => import("./screens/AboutUs.js"));
const ForgotPasswordScreen = lazy(() => import("./screens/ForgotPasswordScreen.js"));
const DashboardScreen = lazy(() => import("./screens/afterLoginScreen/DashboardScreen.js"));
const MyTherapistScreen = lazy(() => import("./screens/admin/MyTherapistScreen.js"));
const MyClientScreen = lazy(() => import("./screens/admin/MyClientScreen.js"));
const SingleClientScreen = lazy(() => import("./screens/admin/SingleClientScreen.js"));
const OnBoardNewClient = lazy(() => import("./screens/admin/OnBoardNewClient"));
const OnBoardNewTherapist = lazy(() => import("./screens/admin/OnBoardNewTherapist"));
const SingleTherapistScreen = lazy(() => import("./screens/admin/SingleTherapistScreen"));
const TherapistUpdateScreen = lazy(() => import("./screens/admin/TherapistUpdateScreen"));
const ClientUpdateScreen = lazy(() => import("./screens/admin/ClientUpdateScreen"));
const AllQuizzesScreen = lazy(() => import("./screens/quiz/AllQuizzesScreen"));
const SingleQuizScreen = lazy(() => import("./screens/quiz/SingleQuizScreen"));
const PaymentRoute = lazy(() => import("./screens/afterLoginScreen/PaymentRoute"));
const ViewSessionLevelMonth = lazy(() => import("./screens/admin/ViewSessionLevelMonth"));
const ViewSessionLevelTherapist = lazy(() => import("./screens/admin/ViewSessionLevelTherapist"));
const UserAnalyticsScreen = lazy(() => import("./screens/admin/UserAnalyticsScreen"));
const FeedbackInfo = lazy(() => import("./screens/admin/FeedbackInfo"));
const PageNotFound = lazy(() => import("./screens/PageNotFound/PageNotFound.js"));
const LoginPageCheck = lazy(() => import("./Containers/Login"));
const RegistrationPageCheck = lazy(() => import("./Containers/Registration"));
const AssessmentQuiz = lazy(() => import("./Containers/Assessment Quiz/index.js"));
const EmployeeWellness = lazy(() => import("./screens/landing-pages/employeeWellness/EmployeeWellness.js"));
const PaymentPlan = lazy(() => import("./Containers/Payment Plan/index.js"));
const SessionMeeting = lazy(() => import("./components/SessionMeeting"));
const WorkshopMeeting = lazy(() => import("./components/WorkshopMeeting"));
const VideoScreen = lazy(() => import("./screens/VideoPlayer/videoPlayer"));
const UserOnboardingQuestions = lazy(() => import("./Containers/UserOnboardingQuestions/UserOnboardingQuestions"));

//css before
//import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "components/index.css";
import { logoutUser, setAvailableFeatures, setUserInfo, setUserToken } from "store/actions";
import {
  ANALYTICS,
  CHALLENGES,
  LIBRARY,
  MOOD_TRACKER,
  MY_BOOKINGS,
  MY_PROFILE,
  OVERVIEW,
  SUPPORT,
  WALLET,
  EVENTS,
  REDEEM_COINS,
  ASSESSMENTS,
  THERAPY_BOOKING,
  ASSESSMENT_QUIZ,
} from "components/UserDashboard";
import {
  HR_ASSESSMENT_SCORE,
  HR_OVERVIEW,
  HR_SESSIONS,
  HR_SUPPORT,
  HR_USERS,
  HR_ZEN_ANALYTICS,
} from "components/HRDashboard";
import OTPVerification from "Containers/OTPVerification";
import EmailLogin from "Containers/EmailLogin";
import ForgotPassword from "Containers/ForgetPassword";
import BuildYourSpace from "Containers/BuildYourSpace";

const BookingSelection = lazy(() => import("./Containers/Booking Selection/index.js"));
const RescheduleSession = lazy(() => import("./Containers/Reschedule/index.js"));
const Home = lazy(() => import("ContainersUpdated/Home"));
const PulseCheckIn = lazy(() => import("./ContainersUpdated/Pulse Check-in"));
const EmployeeEngagement = lazy(() => import("./ContainersUpdated/Employee Engagement"));
const PredictBurnout = lazy(() => import("./ContainersUpdated/Predict Burnout"));
const OverallWellness = lazy(() => import("./ContainersUpdated/Overall wellness"));
const CorporateMentalHealthPrograms = lazy(() =>
  import("screens/landing-pages/corporateMentalHealthPrograms/corporateMentalHealthPrograms")
);
const EmployeeMentalHealth = lazy(() => import("screens/landing-pages/employeeMentalHealth/employeeMentalHealth"));
const CorporateWellness = lazy(() => import("screens/landing-pages/corporateWellness/corporateWellness"));
const CommunityPopup = lazy(() => import("components/CommunityPopup/CommunityPopup"));
const SlackClientScreen = lazy(() => import("screens/admin/SlackClientScreen"));
const AdminHRDashboard = lazy(() => import("screens/admin/AdminHRDashboard"));

const App = () => {
  const [loading, setLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();

  //runs only on initial render
  useEffect(() => {
    refreshTheExistToken();
    // eslint-disable-next-line
    const routesToShowPopup = ["/", "/overall-wellness", "/employee-engagement", "/pulse-check-in", "/predict-burnout"];

    const popupShown = sessionStorage.getItem("popupShown");

    if (!popupShown && routesToShowPopup.includes(location.pathname)) {
      const timeout = setTimeout(() => {
        setShowPopup(true);
        sessionStorage.setItem("popupShown", "true");
      }, 15000);
      return () => clearTimeout(timeout);
    }
  }, []);

  const handleContinue = () => {
    setShowPopup(false);
  };

  const handleJoin = () => {
    setShowPopup(false);
    window.open(
      "https://docs.google.com/forms/d/e/1FAIpQLSdpxs63Wn9o_Hjy3NAqhwCFU_WLCAajV6RvrSzBt09FjEzpLQ/viewform",
      "_blank"
    );
  };

  //gets the logged in user access by renewing access token
  const refreshTheExistToken = async () => {
    try {
      const res = await refreshToken();
      const { userToken, userInfo } = res.data;
      window.localStorage.setItem("auth", JSON.stringify(res.data));
      dispatch(setUserToken(userToken));
      dispatch(setUserInfo(userInfo));

      const allFeatures = await loadAllFeatures(userToken);
      dispatch(setAvailableFeatures(allFeatures.data));

      return setLoading(false);
    } catch (e) {
      logoutUser(dispatch);
      window.localStorage.removeItem("auth");
      setLoading(false);
    }
  };
  // refreshTheExistToken();
  // useLayoutEffect(() => refreshTheExistToken(), [])

  function GetURLId() {
    let { id } = useParams();
    window.location.href = "http://goodlives.in/.well-known/acme-challenge/" + id;
    return null;
  }

  function CorporatePageRedirect() {
    window.location.href = "/overall-wellness";
    return null;
  }

  return (
    <>
      {/* <Navbar /> */}
      {loading ? (
        <Loading show={true} />
      ) : (
        <Suspense fallback={<Loading show={true} />}>
          <Loading />
          <Switch>
            {/* public & auth routes */}
            <Route path="/" exact component={Home} />
            <Route exact path="/.well-known/acme-challenge/:id" children={<GetURLId />} />
            <Route exact path="/corporates" children={<CorporatePageRedirect />} />
            <Route path="/employee-wellness" exact component={EmployeeWellness} />
            <Route path="/corporate-wellness" exact component={CorporateWellness} />
            <Route path="/corporate-mental-health-programs" exact component={CorporateMentalHealthPrograms} />
            <Route path="/employee-mental-health" exact component={EmployeeMentalHealth} />
            <Route path="/about-us" exact component={AboutUs} />
            <Route path="/pulse-check-in" component={PulseCheckIn} />
            <Route path="/employee-engagement" component={EmployeeEngagement} />
            <Route path="/predict-burnout" component={PredictBurnout} />
            <Route path="/overall-wellness" component={OverallWellness} />
            <Route path="/signin" exact component={LoginPageCheck} />
            <Route exact path="/signup" component={RegistrationPageCheck} />
            <Route path="/forgotpassword" exact component={ForgotPasswordScreen} />
            <Route path="/otpVerification" exact component={OTPVerification}/>
            <Route path="/login" exact component={EmailLogin}/>
            <Route path="/forgetPassword" exact component={ForgotPassword} />
            <Route path="/buildYourSpace" exact component={BuildYourSpace} />

            {/* B2B & B2c user routes */}
            <PrivateRoute path="/onboardingQuestions" exact component={UserOnboardingQuestions} />
            {<PrivateRoute path="/quiz-old" exact component={AllQuizzesScreen} />}
            <PrivateRoute path="/quiz/:quizNo" exact component={AssessmentQuiz} />
            <PrivateRoute path="/quiz-old/:quizNo" exact component={SingleQuizScreen} />
            <PrivateRoute path="/session/:meetid/" exact component={SessionMeeting} />
            <PrivateRoute path="/workshop/:workshopId/" exact component={WorkshopMeeting} />
            <PrivateRoute path="/workshops/:workshopId" exact component={DashboardScreen} />
            <PrivateRoute path="/rescheduleSession" exact feature="Therapy Booking" component={RescheduleSession} />
            <PrivateRoute path="/bookingDetails" exact component={PaymentRoute} />
            <PrivateRoute
              path="/sessionBooking/payment-plans"
              exact
              render={(props) => <PaymentPlan {...props} routeProps={{ categoryId: 1, currency: "INR" }} />}
            />
            <PrivateRoute
              path="/sessionBooking/payment-failure"
              exact
              render={(props) => <PaymentPlan {...props} routeProps={{ errorPayment: true }} />}
            />
            <PrivateRoute path="/dashboard" exact render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: "" }} />} />
            <PrivateRoute
              path="/dashboard/overview"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: OVERVIEW }} />}
            />
            <PrivateRoute
              path="/dashboard/sessionBooking"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: THERAPY_BOOKING }} />}
            />
            <PrivateRoute
              path="/dashboard/library"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: LIBRARY }} />}
            />
            <PrivateRoute 
              path="/dashboard/library/video"
              render={(props) => <VideoScreen {...props} />}
            />
            <PrivateRoute
              path="/dashboard/assessments"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: ASSESSMENTS }} />}
            />
            <PrivateRoute
              path="/dashboard/assessments/:urlSlug"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: ASSESSMENT_QUIZ }} />}
            />

            <PrivateRoute
              path="/dashboard/mood-tracker"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: MOOD_TRACKER }} />}
            />
            <PrivateRoute
              path="/dashboard/my-bookings"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: MY_BOOKINGS }} />}
            />
            <PrivateRoute
              path="/dashboard/events"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: EVENTS  }} />}
            />
            <PrivateRoute
              path="/dashboard/wallet"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: WALLET }} />}
            />
            <PrivateRoute
              path="/dashboard/profile"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: MY_PROFILE }} />}
            />
            <PrivateRoute
              path="/dashboard/analytics"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: ANALYTICS }} />}
            />
            <PrivateRoute
              path="/dashboard/challenges"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: CHALLENGES }} />}
            />
            <PrivateRoute
              path="/dashboard/redeem"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: REDEEM_COINS }} />}
            />
            <PrivateRoute
              path="/dashboard/support"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: SUPPORT }} />}
            />

            {/* HR routes */}
            <PrivateRoute
              path="/appsmith/dashboard/overview"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_OVERVIEW }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/users"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_USERS }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/sessions"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_SESSIONS }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/slack"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_ZEN_ANALYTICS }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/assessment-score"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_ASSESSMENT_SCORE }} />}
            />
            <PrivateRoute
              path="/appsmith/dashboard/support"
              exact
              render={(props) => <DashboardScreen {...props} routeProps={{ activeTab: HR_SUPPORT }} />}
            />

            {/* admin routes  */}
            <AdminPrivateRoute path="/ViewSessionLevelMonth" exact component={ViewSessionLevelMonth} />
            <AdminPrivateRoute path="/ViewSessionLevelTherapist" exact component={ViewSessionLevelTherapist} />
            <AdminPrivateRoute path="/feedbackInfo" exact component={FeedbackInfo} />
            <AdminPrivateRoute path="/userAnalytics" exact component={UserAnalyticsScreen} />
            <AdminPrivateRoute path="/mytherapists" exact component={MyTherapistScreen} />
            <AdminPrivateRoute path="/mytherapists/:therapistId" exact component={SingleTherapistScreen} />
            <AdminPrivateRoute path="/mytherapists/update/:therapistId" exact component={TherapistUpdateScreen} />
            <AdminPrivateRoute path="/myclients" exact component={MyClientScreen} />
            <AdminPrivateRoute path="/myclients/:clientId" exact component={SingleClientScreen} />
            <AdminPrivateRoute path="/myclients/update/:clientId" exact component={ClientUpdateScreen} />
            <AdminPrivateRoute path="/myclients/dashboard/:clientId" exact component={AdminHRDashboard} />
            <AdminPrivateRoute path="/onboardnewclient" exact component={OnBoardNewClient} />
            <AdminPrivateRoute path="/onboardnewtherapist" exact component={OnBoardNewTherapist} />
            <AdminPrivateRoute path="/slackClients/:clientId" exact component={SlackClientScreen} />
            <Route component={PageNotFound} />
          </Switch>
          <CommunityPopup isOpen={showPopup} onClose={handleContinue} onJoin={handleJoin} />
        </Suspense>
      )}
    </>
  );
};

export default withRouter(App);