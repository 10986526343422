import axios from 'axios';

export const getWellnessCategories = async (token) =>
    await axios.get(
        process.env.REACT_APP_SERVER_URI + '/api/library',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const getWellnessSelectedCategoryVideos = async (token, selectedCategory, time = []) => {
    // Encode the time array into query parameters
    const timeParams = time.map(t => `time=${encodeURIComponent(t)}`).join('&');

    // Build the base URL
    let url = `${process.env.REACT_APP_SERVER_URI}/api/library/${selectedCategory}`;

    // Append timeParams if it exists
    if (timeParams) {
        url += `?${timeParams}`;
    }
    const response = await axios.get(url, {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    });
    return response
};

export const getWellnessLibSavedVideos = async (token) =>
    await axios.get(
        process.env.REACT_APP_SERVER_URI + '/api/getSavedWellnessVideo',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const saveWellnessLibVideo = async (token, videoData) =>
    await axios.post(
        process.env.REACT_APP_SERVER_URI + '/api/saveWellnessVideo',
        videoData,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const getWellnessLibHistoryVideos = async (token) =>
    await axios.get(
        process.env.REACT_APP_SERVER_URI + '/api/navigation-history',
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const saveWellnessLibHistoryVideo = async (token, videoData) =>
    await axios.post(
        process.env.REACT_APP_SERVER_URI + '/api/navigation-history',
        videoData,
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );

export const unsaveWellnessLibraryVideo = async (token, s3VideoKey) =>
    await axios.post(
        process.env.REACT_APP_SERVER_URI + '/api/unsaveWellnessVideo',
        {
            s3VideoKey
        },
        {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    );