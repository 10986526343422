import { useEffect, useRef, useState } from "react";
import styles from "./MyProfile.module.css";
import { MdClose, MdKeyboardArrowDown, MdKeyboardArrowUp, MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import { getUserAvatars, getUserProfile, updateUserProfile } from "actions/auth";
import { useUserInfoSelector, useUserTokenSelector } from "store/reducers";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setUserInfo } from "store/actions";
import { countryCodes } from "utils/data";

const avatar = {
  female: "https://ucarecdn.com/5c10944e-91bc-4ee6-a259-23565aac6100/avatar3.png",
  male: "https://ucarecdn.com/e6d48801-632e-4d97-b297-bd15292f07ed/avatar2.png",
  other: "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
  "": "https://ucarecdn.com/732dfe6d-fdf3-40c5-a066-e8857ce85d23/avatar1.png",
};

const MyProfilePopup = ({ isOpen, toggleProfilePopup, showError, showMessage, edit = false }) => {
  const userInfo = useUserInfoSelector();
  const [isEditing, setIsEditing] = useState(edit);
  const [fullName, setFullName] = useState(userInfo.fullName);
  const [mobileNo, setMobileNo] = useState(userInfo.phone);
  const [age, setAge] = useState(userInfo.age);
  const [gender, setGender] = useState(userInfo.gender || "");
  const [email, setEmail] = useState(userInfo.email);
  const [countryCode, setCountryCode] = useState(userInfo.countrycode);
  const [showCountryCodeDropdown, setShowCountryCodeDropdown] = useState(false);
  const [avatars, setAvatars] = useState([]);
  const [scrollIndex, setScrollIndex] = useState(0);
  const token = useUserTokenSelector();
  const dispatch = useDispatch();
  const avatarListRef = useRef(null);
  const [selectedAvatar, setSelectedAvatar] = useState(userInfo.avatar_url || "");

  const genderOptions = [
    {
      name: "Male",
      value: "male",
    },
    {
      name: "Female",
      value: "female",
    },
    {
      name: "Other",
      value: "other",
    },
  ];

  const updateProfile = async () => {
    try {
      if (fullName.length < 5) {
        throw new Error("Please provide full name");
      } else if (mobileNo.length > 10) {
        throw new Error("Please provide valid mobile number");
      } else if (!age || age == 0) {
        throw new Error("Please provide age");
      } else if (!["male", "female", "other"].includes(gender.toLowerCase())) {
        throw new Error("Please provide gender");
      } else if (!(countryCode && countryCodes.includes(countryCode))) {
        throw new Error("Please provide your country's mobile code");
      } else if (userInfo.categoryId !== 1 && !mobileNo) {
        throw new Error("Please provide mobile number");
      }

      const payload = { fullName, age, gender: gender.toLowerCase(), countryCode , avatarUrl: selectedAvatar};
      if (userInfo.categoryId !== 1) {
        payload.mobileNo = mobileNo;
      }

      await updateUserProfile(token, payload);
      await fetchUserProfile();
      setIsEditing(false);
      showMessage("Profile updated successfully");
    } catch (error) {
      showError(error.response?.data?.error || error.message);
    }
  };

  const getUserUnlockedAvatars = async () => {
    try {
      const res = await getUserAvatars(token);
      setAvatars(res.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUserProfile = async () => {
    getUserProfile(token)
      .then((res) => {
        const { user } = res.data;
        setFullName(user.fullName);
        setMobileNo(user.phone?.toString());
        setAge(user.age);
        setGender(user.gender);
        setEmail(user.email);
        setCountryCode(user.countrycode || "+91");
        dispatch(setUserInfo(user));
        if (edit) {
          toggleProfilePopup();
        }
      })
      .catch((error) => {
        console.warn(error);
        showError(error.response?.data?.error || error.message);
      });
  };

  useEffect(() => {
    setIsEditing(false);
    getUserUnlockedAvatars()
  }, [isOpen]);

  useEffect(() => {
    setIsEditing(edit);
  }, [edit]);

  useEffect(() => {
    const user = userInfo;
    setFullName(user.fullName);
    setMobileNo(user.phone?.toString());
    setAge(user.age);
    setGender(user.gender);
    setEmail(user.email);
    setCountryCode(user.countrycode || "+91");
    if (user.avatar_url) {
      setSelectedAvatar(user.avatar_url);
    }
  }, [userInfo])

    // Scroll handlers
    const handleScrollLeft = () => {
      setScrollIndex(prev => Math.max(prev - 1, 0));
    };
  
    const handleScrollRight = () => {
      setScrollIndex(prev => Math.min(prev + 1, avatars.length - 3));
    };
  
    // Combine gender avatar and unlocked avatars
    const combinedAvatars = [avatar[gender], ...avatars?.map?.(a => a.cdn_url)];

    const handleAvatarClick = (avatarUrl) => {
        setSelectedAvatar(avatarUrl); // Otherwise, set the clicked avatar as selected
    };

  return (
    <div className={`${styles["profile-cont"]} ${!isOpen ? styles["profile-cont-hidden"] : ""}`}>
      <div className={styles.profile}>
        <div className={styles["profile-div"]}>
          {!edit && (
            <button className={styles["profile-close"]} onClick={toggleProfilePopup}>
              <MdClose size={16} />
            </button>
          )}
          <div className={styles["profile-cta-cont"]}>
            {isEditing ? (
              <button className={styles["profile-cta"]} onClick={updateProfile} style={{ backgroundColor: "#49AF7C" }}>
                Save
              </button>
            ) : (
              <button className={styles["profile-cta"]} onClick={() => setIsEditing(true)} style={{ backgroundColor: "#49AF7C" }}>
                Edit
              </button>
            )}
          </div>
          {
            edit && (
              <div className={styles["profile-mandatory-msg"]}>
                Please provide all details to proceed.
              </div>
            )
          }
          {/* Avatar List Section */}
          <div className={styles["avatar-list-cont"]}>
            {/* <button
              onClick={handleScrollLeft}
              className={`${styles["avatar-scroll-btn-left"]} ${!isEditing || scrollIndex === 0 ? styles.disabled : ""}`}
              disabled={!isEditing || scrollIndex === 0}
            >
              <MdKeyboardArrowLeft size={24} />
            </button> */}

            <div className={styles["avatar-list"]} ref={avatarListRef}>
              {combinedAvatars.map((url, idx) => (
                <img
                  key={idx}
                  src={url}
                  alt="Avatar"
                  className={`${styles["avatar-item"]} ${url === selectedAvatar ? styles["avatar-item-center"] : ""}`}
                  onClick={() => isEditing && handleAvatarClick(url)} // Only handle click if isEditing is true
                  style={{ cursor: isEditing ? "pointer" : "default" }}
                />
              ))}
            </div>

            {/* <button
              onClick={handleScrollRight}
              className={`${styles["avatar-scroll-btn-right"]} ${!isEditing || scrollIndex >= combinedAvatars.length - 3 ? styles.disabled : ""}`}
              disabled={!isEditing || scrollIndex >= combinedAvatars.length - 3}
            >
              <MdKeyboardArrowRight size={24} />
            </button> */}
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              disabled={!isEditing}
              value={fullName}
              onChange={(evt) => setFullName(evt.target.value)}
            />
          </div>
          <div className={styles["profile-div-input-cont2"]}>
            <div className={styles["profile-div-input-cont"]} style={{ width: "65%" }}>
              <label for="contact">Mobile number</label>
              <div className={styles["profile-div-input-mobile"]} style={{boxShadow:'1px 1px 10px 1px #ececec',borderRadius:'25px'}}>
                <div className={styles["profile-div-input-mobile-cta-cont"]}>
                  <button
                    disabled={!isEditing || userInfo.categoryId === 1}
                    style={{border:"0px",borderRight:'1px solid rgba(57, 75, 66, 0.4)'}}
                    onClick={() => setShowCountryCodeDropdown((val) => !val)}
                    className={styles["profile-div-input-mobile-cta"]}
                  >
                    {countryCode} {showCountryCodeDropdown ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}{" "}
                  </button>
                  {showCountryCodeDropdown && (
                    <div className={styles["profile-div-input-mobile-cta-drpdwn"]}>
                      {countryCodes.sort().map((code) => (
                        <button onClick={() => setCountryCode(code)}>{code}</button>
                      ))}
                    </div>
                  )}
                </div>
                <input
                  type="number"
                  style={{boxShadow:'0px 0px 0px 0px'}}
                  id="contact"
                  disabled={!isEditing || userInfo.categoryId === 1}
                  value={mobileNo}
                  maxLength={10}
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(0, e.target.maxLength);
                  }}
                  onChange={(evt) => setMobileNo(evt.target.value)}
                />
              </div>
            </div>
            <div className={styles["profile-div-input-cont"]} style={{ width: "30%" }}>
              <label for="age">Age</label>
              <input
                type="number"
                id="age"
                className={styles["profile-div-input-age"]}
                disabled={!isEditing}
                value={age}
                maxLength={2}
                onInput={(e) => {
                  e.target.value = e.target.value.slice(0, e.target.maxLength);
                }}
                onChange={(evt) => setAge(evt.target.value)}
              />
            </div>
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="email">Email</label>
            <input type="text" id="email" disabled value={email} />
          </div>
          <div className={styles["profile-div-input-cont"]}>
            <label for="gender">Gender</label>
            <select
              value={gender}
              id="gender"
              name="gender"
              disabled={!isEditing}
              onChange={(evt) => setGender(evt.target.value)}
              placeholder="Please select gender"
            >
                <option value="" disabled hidden >Select gender</option>
              {genderOptions.map((gender) => (
                <option key={gender.value} value={gender.value}>
                  {gender.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfilePopup;
